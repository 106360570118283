<template>
  <div class="content-inline">
    <div class="arrow-container">
      <img
        @click="goBack()"
        class="btn-back"
        src="../../assets/icons/back_arrow.png"
      />
    </div>
    <div class="text-container">
      <div class="detail-header font-bold">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bucketUrl: process.env.VUE_APP_BUCKET_URL,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-inline {
  display: flex;
  flex-direction: row;
  height: 45px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px #2140a61a;
  z-index: 10;
  background: black;
}

.arrow-container {
  align-items: left;
  //   width: 30%;
}

.btn-back {
  position: absolute;
  z-index: 10;
  left: 15px;
  top: 13px;
  cursor: pointer;
}

.arrow-container img {
  width: 13px;
  height: 20px;
  filter: invert(1);
}

.detail-header {
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}
</style>
