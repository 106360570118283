import Vue from "vue";
import Vuex from "vuex";
import "../utill/api/axiosConfig";
import { token } from '../utill/jwt';
import * as apiMerchants from '../utill/api/merchants';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const secureLS = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_SECRET_KEY,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentLang: null,
    userInfo: null,
    merchantInfo: null,
    snackbarMessage: "",
    couponCode: "",
    couponDetail: {},
    showSidebar: false,
    activatedSidebar: false,
  },
  mutations: {
    SET_CURRENT_LANGUAGE(state, msg) {
      state.currentLang = msg;
    },
    SET_USER_INFO(state, msg) {
      state.userInfo = msg;
    },
    SET_MERCHANT_INFO(state, msg) {
      state.merchantInfo = msg;
    },
    SET_SNACKBAR_MESSAGE(state, msg) {
      state.snackbarMessage = msg;
    },
    SET_SHOW_SIDEBAR(state, msg) {
      state.showSidebar = msg;
    },
    SET_ACTIVATED_SIDEBAR(state, msg) {
      state.activatedSidebar = msg;
    },
    SET_COUPON_CODE(state, msg) {
      state.couponCode = msg;
    },
    SET_COUPON_DETAIL(state, msg) {
      state.couponDetail = msg;
    },
  },
  actions: {
    getUserInfo({ commit }) {
      if (token.getUserId()) {
        const userId = parseInt(token.getUserId() as string, 10);
        apiMerchants.getUserInfo(userId).then((result: any) => {
          if (result.successful) {
            commit('SET_USER_INFO', result.data);
          } else {
            commit('SET_USER_INFO', null);
          }
        });
        commit('SET_USER_INFO', null);
      }
    },
    getMerchantInfo({ commit }) {
      const domain = parseInt(token.getDomain() as string, 10);
      if (domain) {
        apiMerchants.getMerchantInfo(domain).then((result: any) => {
          if (result.successful) {
            commit('SET_MERCHANT_INFO', result.data);
          } else {
            commit('SET_MERCHANT_INFO', null);
          }
        });
      }
    },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => secureLS.get(key),
        setItem: (key, value) => secureLS.set(key, value),
        removeItem: key => secureLS.remove(key)
      }
    })
  ],
});
