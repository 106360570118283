<template>
  <div class="custom-modal-backdrop">
    <div class="custom-modal-container">
      <div class="custom-modal-box">
        <div class="custom-modal-contents">
          <div class="modal-txt-section">
            <div class="img-header">
              <img
                v-if="!contents.showStatusSuccess"
                src="../../assets/icons/wrong.svg"
              />
              <img
                v-if="contents.showStatusSuccess"
                src="../../assets/icons/correct.svg"
              />
            </div>
            <div class="title mb-0" v-html="contents.title"></div>
            <div class="subtitle mb-0" v-html="contents.subtitle"></div>
            <div class="custom-modal-body" v-html="contents.staff"></div>
            <div
              class="custom-modal-body"
              v-html="contents.transactionDate"
            ></div>
          </div>
          <div class="modal-footer">
            <div class="btn-close" @click="$emit('close')">
              {{ contents.btnText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexStore from '@/store/index.ts';
export default {
  store: indexStore,
  data() {
    return {
      currentLang: this.$store.state.currentLang,
    };
  },
  props: {
    contents: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.img-header {
  width: 100%;
  margin-top: 20px;
}

.img-header img {
  width: 80px;
  height: 80px;
}

.custom-modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 74px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
}

.custom-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 40px;
  width: 500px;
  /* min-height: 400px; */
}

.custom-modal-box {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  text-align: center;
  width: 500px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-modal-contents {
  overflow-y: scroll;
}

.custom-modal-content {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 22px;
  text-align: center;
  color: #000926;
  margin-top: 15px;
}

.subtitle {
  font-size: 14px;
  text-align: center;
  color: #000926;
  margin-top: 12px;
}

.modal-txt-section {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.custom-modal-body {
  margin: 5px 0px;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}


.modal-footer {
  border-top: 1px solid #E0E6F8;
  padding: 0;
  margin: 0;
  position: relative;
  left: 0;
  bottom: 0;
}

.btn-close {
  width: 100%;
  height: 48px;
  font-size: 16px;
  text-align: center;
  padding-top: 7px;
  color: #000000;
  font-weight: bold;
}
</style>
