<template>
  <div class="w-100">
    <NavBar :title="$t('main.scanBarcode')" />
    <BarcodeScanner />
  </div>
</template>

<script>
import BarcodeScanner from '@/components/scanner/BarcodeScanner.vue';
import NavBar from '@/components/navigationbar/NavigationBar.vue';
export default {
  name: 'scanner',
  data() {
    return {};
  },
  components: {
    BarcodeScanner,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  padding: 0 0;
  margin: 0 0;
  width: 100%;
  height: 100%;
}

::v-deep .scanner-container video{
  width: 100%;
}
</style>
