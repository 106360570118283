import aes from 'crypto-js/aes';
import utcUtf8 from 'crypto-js/enc-utf8';

const secretKey = process.env.VUE_APP_SECRET_KEY;

export function primoEncrypt(message: string | number) {
  return aes.encrypt(String(message), secretKey).toString();
}

export function primoDecrypt(cipherText: string) {
  const bytes  = aes.decrypt(cipherText, secretKey);
  const originalText = bytes.toString(utcUtf8);
  return originalText;
}
