<template>
  <div class="main-container">
    <md-progress-bar md-mode="indeterminate" v-if="sending" />
    <div class="background-header">
      <img id="header-img" src="../assets/images/header_bg.svg" alt="" />
    </div>
    <div class="content-container">
      <div class="title font-bold">{{ $t('main.title') }}</div>
      <form novalidate class="md-layout" @submit.prevent="validateUser">
        <div class="input-group">
          <md-field md-clearable :class="getValidationClass('partnerCode')">
            <label for="partnerCode">{{ $t('main.partnerCode') }}</label>
            <md-input
              name="partnerCode"
              id="partnerCode"
              v-model="form.partnerCode"
              :disabled="sending"
            />
            <span class="md-error" v-if="!$v.form.partnerCode.required">{{
              $t('main.partnerCodeError')
            }}</span>
          </md-field>

          <md-field md-clearable :class="getValidationClass('username')">
            <label for="username">{{ $t('main.username') }}</label>
            <md-input
              name="username"
              id="username"
              v-model="form.username"
              :disabled="sending"
            />
            <span class="md-error" v-if="!$v.form.username.required">{{
              $t('main.usernameError')
            }}</span>
          </md-field>

          <md-field :class="getValidationClass('password')">
            <label for="password">{{ $t('main.password') }}</label>
            <md-input
              name="password"
              id="password"
              type="password"
              v-model="form.password"
              :disabled="sending"
            />
            <span class="md-error" v-if="!$v.form.password.required">{{
              $t('main.passwordError')
            }}</span>
          </md-field>
        </div>
        <div class="footer">
          <div class="btn">
            <md-button type="submit" class="primo-button" :disabled="sending">{{
              $t('main.login')
            }}</md-button>
          </div>
          <div class="row-group">
            <div class="imageFlag">
              <img
                src="../assets/icons/thailand.png"
                @click="changeLang('en')"
                v-if="currentLang == 'th'"
              />
              <img
                src="../assets/icons/united-states.png"
                @click="changeLang('th')"
                v-if="currentLang == 'en'"
              />
            </div>
            <div class="reserved">
              {{ $t('main.reserved') }}
            </div>
          </div>
        </div>
      </form>
    </div>
    <transition name="fade"
      ><alert-modal
        v-if="alertShow"
        :contents="this.contentAlert"
        @close="alertShow = false"
    /></transition>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import storeIndex from '@/store/index.ts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import * as ApiMerchants from '@/utill/api/merchants';
import { token } from '@/utill/jwt';
import alertModal from "@/components/dialog/alert_modal.vue";

export default {
  name: 'LoginForm',
  mixins: [validationMixin],
  created() {
    localStorage.clear();
  },
  components: {
    "alert-modal": alertModal,  
  },
  data() {
    return {
      currentLang: this.$store.state.currentLang,
      form: {
        partnerCode: null,
        username: null,
        password: null,
      },
      sending: false,
      alertShow: false,
    };
  },
  validations: {
    form: {
      partnerCode: {
        required,
      },
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.currentLang = lang;
      this.$store.commit("SET_CURRENT_LANGUAGE", this.$i18n.locale);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.partnerCode = null;
      this.form.username = null;
      this.form.password = null;
    },
    showAlertModal(title, subtitle, moreDetail = null) {
      this.contentAlert = {
        title: title,
        desc: subtitle,
        moreDetail: moreDetail,
      };
      this.alertShow = true;
    },
    loginUser() {
      this.sending = true;

      const requestBody = {
        "grant_type": "hash",
        "username" : this.form.username,
        "password" : this.form.password,
        "transaction_channel" :"merchant_web",
        "partner_code": this.form.partnerCode
      };
      this.sending = false;
      let userId;
      ApiMerchants.login(requestBody).then(result => {
        const data = result.data;
        if (result.successful) {
          token.setAccess(data.access_token);
          token.setRefresh(data.refresh_token);
          token.setUserId(data.user_id);
          userId = data.user_id;
        } else {
          this.showAlertModal(this.$t('main.dataIncorrect'), this.$t(`error.${data.error_code}`));
        }
      }).then(() => {
        if (userId) 
          ApiMerchants.getMerchantUser(userId).then(resultMerchantUser => {
            if (resultMerchantUser.successful) {
              token.setDomain(resultMerchantUser.data.domain);
              token.setRole(resultMerchantUser.data.groups[0].id);
              this.clearForm();
              this.navigateToHome();}
          })
      })
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loginUser();
      }
    },
    navigateToHome() {
      this.$router.push('home');
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  margin: 60% 0 0 0;
  padding: 25px;
  z-index: 1;
}

.background-header {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
}

.background-header img {
  width: 100%;
  position: relative;
  height: auto;
}

.title {
  font-size: 45px;
  text-align: left;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.imageFlag img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.imageFlag {
  text-align: right;
  margin: 0px 10px 0px 0px;
  width: 40%;
}

.row-group {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.reserved {
  text-align: left;
  font-size: 12px;
  padding-top: 5px;
}

.footer {
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 0px;
  margin: 10px 0px 0px 0px;
}

.btn {
  width: 100%;
  margin: 0;
  padding: 0px 15px 0px 0px;
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media screen and (max-width: 340px) {
  .title {
    font-size: 35px;
    text-align: left;
  }

  #header-img {
    content: url('../assets/images/green-water.svg');
  }

  .content-container {
    margin: 30% 0 0 0;
  }
}

@media screen and (min-width: 375px) {
  .background-header img {
    top: -100px;
  }
  .content-container {
    width: 100%;
    margin: 40% 0 0 0;
    padding: 25px;
    z-index: 1;
  }
}

@media screen and (min-width: 768px) {
  .background-header img {
    top: -150px;
  }
}
</style>
