import Vue from "vue";
import axios from 'axios';
import VueAxios from "vue-axios";
import { token } from '../jwt';
import store from '@/store';
import router from '@/router';
import { refreshToken } from './merchants';
import i18n from '@/i18n';

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.defaults.timeout = 10000;

Vue.axios.interceptors.request.use(
  (request: any): any => {
    request.headers['Authorization'] = 'Bearer ' + token.getAccess();
    request.headers['Authorization-type'] = 'merchant';
    return request;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

Vue.axios.interceptors.response.use(
  (response: any): any => {
    return response.data;
  },
  (error: any) => {
    const errorResponse = error.response;
    if (errorResponse.data) {
      const errorCode = errorResponse.data.error_code;
      const ignoreErrors = ['US007', 'US008', 'US017', 'CM010'];
      const isIgnore = ignoreErrors.filter(ignoreError => ignoreError == errorCode);
      if (isIgnore.length == 1) {
        // do not show snackbar
      }
      else if (errorCode == 10 || errorCode == 98 || errorCode == 99) {
        router.push('/login');
        store.commit('SET_SNACKBAR_MESSAGE', i18n.t(error[errorResponse.data.error_code]));
      }
      else if (errorCode == 27) {
        refreshToken().then((result: any) => {
          if (result.successful) {
            const data = result.data;
            token.setAccess(data.access_token);
            token.setRefresh(data.refresh_token);
            token.setUserId(data.user_id);
            store.commit('SET_SNACKBAR_MESSAGE', 'Refresh token successfully');
          } else {
            router.push('/login');
          }
        });
      }
      else {
        store.commit('SET_SNACKBAR_MESSAGE', i18n.t(`error.${errorResponse.data.error_code}`));
      }
    }
    return errorResponse;
  }
);
