import en from './translations/en.json';
import th from './translations/th.json';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);
export default new VueI18n({
    messages: {
        en: en,
        th: th
    },
    silentTranslationWarn: process.env.NODE_ENV === 'production'
})