<template>
  <div>
    <Nav :title="$t('main.couponDetail')" />
    <CouponDetail />
  </div>
</template>

<script>
import Nav from '@/components/navigationbar/NavigationBar.vue';
import CouponDetail from '@/components/coupon_detail/coupon_detail.vue';
export default {
  name: 'coupon_detail',
  data() {
    return {};
  },
  components: {
    Nav,
    CouponDetail,
  },
};
</script>

<style lang="scss" scoped></style>
