/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue";
import "./axiosConfig";
import { token } from "@/utill/jwt";

export function login(requestBody: any) {
  return Vue.axios.post("merchant-users/login", requestBody);
}

export function logout(requestBody: any) {
  return Vue.axios.post('merchant-users/logout', requestBody);
}

export function refreshToken() {
  return Vue.axios.post("merchant-users/grant_token", {
    grant_type: "refresh_token",
    refresh_token: token.getRefresh(),
    transaction_channel: "merchant_web",
  });
}

export function getUserInfo(id: number) {
  return Vue.axios.get("merchant-users/info/" + id);
}

export function getMerchantInfo(id: number) {
  return Vue.axios.get("merchants/" + id);
}

export function getMerchantUser(id: number) {
  return Vue.axios.get("merchant-users/" + id);
}

export function getCouponDetail(body: Record<string, string>) {
  return Vue.axios.post("coupons/detail", body);
}

export function redeemCoupon(body: Record<string, string>) {
  return Vue.axios.post("coupons/redeem", body);
}
