<template>
  <div>
    <div class="header">
      <div class="menu-bars" @click="setShowSidebar()">
        <i class="fas fa-bars"></i>
      </div>
      <img
        class="primo-logo"
        @click="navigateToHome()"
        src="../../assets/images/primo-logo-white.png"
      />
    </div>
    <div class="header-height" />
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("SET_ACTIVATED_SIDEBAR", false);
    this.$store.commit("SET_SHOW_SIDEBAR", false);
  },
  computed: {
    showSidebar() {
      return this.$store.state.showSidebar;
    },
  },
  methods: {
    setShowSidebar() {
      this.$store.commit("SET_ACTIVATED_SIDEBAR", true);
      this.$store.commit("SET_SHOW_SIDEBAR", true);
    },
  },
};
</script>

<style lang="scss" scoped>
$VAR-header-height: 45px;

.header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: $VAR-header-height;
  background: black;
  position: relative;
}

.menu-bars {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 22px;
  color: white;
}

.primo-logo {
  width: 109px;
  height: 42px;
  display: block;
  margin: auto;
}

.header-height {
  height: $VAR-header-height;
}
</style>
