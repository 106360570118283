<template>
  <div class="w-100">
    <NavBar :title="$t('main.scanqr')" />
    <QRScanner />
  </div>
</template>

<script>
import QRScanner from '@/components/scanner/QRScanner.vue';
import NavBar from '@/components/navigationbar/NavigationBar.vue';
export default {
  name: 'scanner',
  data() {
    return {};
  },
  components: {
    QRScanner,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
  height: 100%;
}
</style>
