import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import ScanQR from "../views/ScannerQR.vue";
import ScanBarcode from '../views/ScannerBarcode.vue';
import TypeCode from '../views/TypeCode.vue';
import CouponDetail from '../views/CouponDetail.vue';
import { token } from '@/utill/jwt';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      requiresAuth: true
    },
    component: Home
  },
  {
    path: "/scanqr",
    name: "Scanqr",
    meta: {
      requiresAuth: true
    },
    component: ScanQR
  },
  {
    path: "/scanbarcode",
    name: "ScanBarcode",
    meta: {
      requiresAuth: true
    },
    component: ScanBarcode
  },
  {
    path: "/typecode",
    name: "TypeCode",
    meta: {
      requiresAuth: true
    },
    component: TypeCode
  },
  {
    path: "/coupon_detail",
    name: "CouponDetail",
    meta: {
      requiresAuth: true
    },
    component: CouponDetail
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !token.getAccess()) next({ name: 'Login' })
  else next()
})

export default router;
