
import { primoEncrypt, primoDecrypt } from '@/utill/crypto';

function setLocalstorage(key: string, value: string) {
  if (!key) {
    localStorage.removeItem(key);
    return;
  }
  localStorage.setItem(key, primoEncrypt(value));
}

export const token = {
  setAccess: (token: string): void => {
    localStorage.setItem('access_token', token);
  },
  getAccess: (): any => {
    return localStorage.getItem('access_token');
  },
  removeAccess: (): void => {
    localStorage.removeItem('access_token');
  },
  setRefresh: (token: string): void => {
    localStorage.setItem('refresh_token', token);
  },
  getRefresh: (): any => {
    return localStorage.getItem('refresh_token');
  },
  removeRefresh: (): void => {
    localStorage.removeItem('refresh_token');
  },
  setUserId: (user: string): any => {
    setLocalstorage('userId', user);
  },
  getUserId: (): string | null => {
    const cipherUserId = localStorage.getItem('userId');
    if (!cipherUserId) return null;
    return primoDecrypt(cipherUserId);
  },
  removeUserId: (): void => {
    localStorage.removeItem('userId');
  },
  setDomain: (domain: string): any => {
    setLocalstorage('domain', domain);
  },
  getDomain: (): string | null => {
    const cipherDomain = localStorage.getItem('domain');
    if (!cipherDomain) return null;
    return primoDecrypt(cipherDomain);
  },
  removeDomain: (): void => {
    localStorage.removeItem('domain');
  },
  setRole: (role: string): any => {
    setLocalstorage('role', role);
  },
  getRole: (): string | null => {
    const cipherRole = localStorage.getItem('role');
    if (!cipherRole) return null;
    return primoDecrypt(cipherRole);
  },
  removeRole: (): void => {
    localStorage.removeItem('role');
  },
}