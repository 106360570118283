import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(BootstrapVue, IconsPlugin);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
