<template>
  <div class="custom-modal-backdrop">
    <div class="custom-modal-container">
      <div class="custom-modal-box">
        <div class="custom-modal-contents">
          <div class="custom-modal-content">
            <div class="modal-txt-section">
              <div class="img-header">
                <img src="../../assets/icons/exclamation.svg" />
              </div>
              <div class="custom-modal-header" v-html="contents.title"></div>
              <div class="custom-modal-body" v-html="contents.desc"></div>
              <div
                class="custom-modal-more-detail"
                v-html="contents.moreDetail"
                @click="$emit('close')"
              ></div>
            </div>
            <div class="modal-footer">
              <div class="content-inline">
                <div class="btn-cancel w-100" @click="$emit('close')">
                  {{ $t("main.close") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

.custom-modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
}

.custom-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 30px;
  width: 100%;
}

.custom-modal-box {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-modal-contents {
  overflow: hidden;
}

.custom-modal-content {
  display: flex;
  flex-direction: column;
}

.custom-modal-header {
  font-family: "Sukhumvit_Bold";
  font-size: 18px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}

.modal-txt-section {
  padding: 30px 24px 10px 24px;
  display: flex;
  flex-direction: column;
}

.custom-modal-body {
  margin-top: 20px;
  text-align: center;
  color: #000926;
  font-size: 14px;
}

.custom-modal-more-detail {
  text-align: center;
  color: #006CFF;
  font-size: 14px;
}

.modal-footer {
  border-top: none;
  padding: 0;
  margin-top: 20px;
  position: relative;
  background: #ebebeb;
  text-align: center;
  justify-content: center;
  height: 40px;
}

.btn-cancel {
  color: black;
  font-size: 16px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  padding-top: 8px;
  font-weight: bold;
}

.content-inline {
  display: flex;
  flex-direction: row;
}

.img-header {
  width: 100%;
  margin-top: 20px;
}

.img-header img {
  width: 50px;
  height: 50px;
}
</style>
