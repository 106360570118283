<template>
    <div class="custom-modal-backdrop">
    <div class="custom-modal-container">
      <div class="custom-modal-box">
        <div class="custom-modal-contents">
          <div class="custom-modal-content">
            <div class="modal-txt-section">
              <div class="custom-modal-header">{{ contents.title }}</div>
              <div class="custom-modal-body">{{ contents.desc }}</div>
            </div>
            <div class="modal-footer">
              <div class="content-inline">
                <div class="btn-cancel" @click="$emit('close')">{{ $t('main.cancel') }}</div>
                <div class="btn-submit" @click="$emit('confirmUsed')">{{ $t('main.confirm')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
    contents: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}


.custom-modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
}

.custom-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 30px;
  width: 100%;
}

.custom-modal-box {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  text-align: center;
  width: 100%;
  background: #FFFFFF;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
}

.custom-modal-contents {
  overflow: hidden;
  width: 100%;
}


.custom-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.custom-modal-header {
  font-size: 24px;
  text-align: center;
  color: #000000;
}

.modal-txt-section {
  padding: 30px 24px 10px 24px;
  display: flex;
  flex-direction: column;
}

.custom-modal-body {
  margin: 20px 0px;
  text-align: center;
  color: #000926;
  font-size: 14px;
}


.modal-footer {
  border-top: 1px solid #E0E6F8;
  padding: 0;
  margin: 0;
  position: relative;
  justify-content: center;
  text-align: center;
  height: 40px;
  width: 100%;
}

.btn-cancel {
    border-right: 1px solid #E0E6F8;
    font-size: 16px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    padding-top: 8px;
    width: 50%;
}

.btn-submit {
    border: none;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    text-align: center;
    cursor: pointer;
    padding-top: 8px;
    width: 50%;
}

.content-inline {
  display: flex;
  flex-direction: row;
  width:100%;
}

</style>