<template>
  <div v-if="activatedSidebar">
    <div :class="{ backdrop: showSidebar }" />
    <aside :class="animationClassObject">
      <div class="title">
        <img class="primo-logo" src="../../assets/images/primo-logo.png" />
        <img @click="setHideSidebar" src="../../assets/icons/close-24px.svg" />
      </div>
      <!-- <div class="menu">
        <div class="menu__link">
          <span>ตั้งค่าบัญชี</span>
          <img src="../../assets/icons/chevron_right-24px.svg" />
        </div>
        <hr />
        <div class="menu__link">
          <span>ประวัติการใช้งาน</span>
          <img src="../../assets/icons/chevron_right-24px.svg" />
        </div>
        <hr />
      </div>
      <span class="helper-header">ช่วยเหลือ</span>
      <div class="helper-link">
        <img src="../../assets/icons/info-24px.svg" />
        <span>ช่วยเหลือ</span>
      </div> -->
      <hr />
      <div class="helper-link" @click="modalLogout()">
        <img src="../../assets/icons/exit_to_app-24px.svg" />
        <span>ออกจากระบบ</span>
      </div>
      <hr />
    </aside>
    <transition name="fade">
      <confirm-modal
        v-if="confirmShow"
        :contents="this.contentConfirm"
        @close="confirmShow = false"
        @confirmUsed="logout()"
      />
    </transition>
  </div>
</template>

<script>
import { token } from '@/utill/jwt';
import { logout as apiLogout } from '@/utill/api/merchants';
import confirmModal from "@/components/dialog/confirm_modal.vue";

export default {
  components: {
    confirmModal
  },
  computed: {
    animationClassObject() {
      return {
        "aside--open": this.showSidebar,
        "aside--close": !this.showSidebar,
      };
    },
    showSidebar() {
      return this.$store.state.showSidebar;
    },
    activatedSidebar() {
      return this.$store.state.activatedSidebar;
    }
  },
  methods: {
    navigateToHome() {
      this.$router.push("home");
    },
    setHideSidebar() {
      this.$store.commit("SET_SHOW_SIDEBAR", false);
    },
    showMessageLogout() {
      this.$store.commit("SET_SNACKBAR_MESSAGE", "Logout Successful");
    },
    logout()  {
      const requestBody = {
        "access_token": token.getAccess(),
        "transaction_channel" :"merchant_web",
      };
      apiLogout(requestBody).then(result => {
        if (result.successful) {
          this.showMessageLogout();
          this.$router.push('login');
        }
      });
    },
    modalLogout() {
      this.contentConfirm = {
        title: this.$t("main.confirmLogout")
      };
      this.confirmShow = true;
    }
  },
  data(){
    return {
      confirmShow: false,
      contentConfirm: {}
    };
  }
};
</script>


<style lang="scss" scoped>
$VAR-navbar-width: 317px;

.backdrop {
  position: fixed;
  z-index: 750;
  background: black;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  top: 0;
}

aside {
  position: fixed;
  float: left;
  width: $VAR-navbar-width;
  height: 100vh;
  white-space: nowrap;
  top: 0;
  z-index: 775;
  background-color: white;
  font-family: Sukhumvit_SemiBold;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.aside--open {
  animation-name: aside--open--animation;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

@keyframes aside--open--animation {
  from {
    left: -250px;
  }
  to {
    left: 0px;
  }
}

.aside--close {
  animation-name: aside--close--animation;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

@keyframes aside--close--animation {
  from {
    left: 0px;
  }
  to {
    left: -$VAR-navbar-width;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 30px 10px;
}

.fa-times {
  align-self: center;
}

.primo-logo {
  width: 130px;
  height: 48px;
  display: block;
}

.menu__link {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  > span {
    font-size: 16px;
    color: black;
    padding-left: 20px;
    text-decoration: none;
  }
  > img {
    padding-right: 20px;
  }
}

.helper-header {
  padding: 15px 0 20px 20px;
  font-weight: 600;
  display: block;
}

.helper-link {
  padding-left: 30px;
  > span {
    color: black;
    text-decoration: none !important;
  }
  > img {
    padding-right: 8px;
  }
}
</style>
