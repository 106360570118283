<template>
  <div>
    <!-- meta disable zoom display -->
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <meta name="HandheldFriendly" content="true" />
    <router-view></router-view>
    <md-snackbar :md-duration="snackbarDuration" :md-active.sync="showSnackbar">
      <span>{{ snackbarMessage }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { useI18n } from 'vue-i18n';
import storeIndex from '@/store/index.ts';
import VueMaterial from 'vue-material';
import Vue from 'vue';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.use(VueMaterial);
export default {
  store: storeIndex,
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  async beforeMount() {
    this.setCurrentLang('th');
  },
  components: {},
  data() {
    return {
      isOffline: false,
      backOnline: false,
      showSnackbar: false,
      snackbarDuration: 3500,
    };
  },
  computed: {
    snackbarMessage() {
      return this.$store.state.snackbarMessage;
    },
  },
  watch: {
    snackbarMessage: function () {
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, this.snackbarDuration
      );
    },
    isOffline: function (newValue) {
      if (!newValue) {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Back Online");
        location.reload();
      } else {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "You are offline, Please check internet connection.");
      }
    }
  },
  methods: {
    setCurrentLang(language) {
      this.$i18n.locale = language;
      this.$store.state.currentLang = this.$i18n.locale;
    },
  	updateOnlineStatus(event) {
    	const { type } = event;
      this.isOffline = type === 'offline';
    },
    resetTimer(_event) {
      const tenMinute = 600000;
      let timeoutHandler = null;
      clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
        this.$store.commit("SET_SNACKBAR_MESSAGE", "Welcome back, fetching new data");
        location.reload();
      }, tenMinute);
    },
  },
  mounted() {
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    window.addEventListener('mousedown', this.resetTimer);
  },
  beforeDestroy() {
    window.removeEventListener('online',  this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    window.removeEventListener('mousedown', this.resetTimer);
  }
};
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import '@/assets/styles/global.scss';

body,
html {
  overflow-x: hidden;
}
</style>
