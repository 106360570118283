<template>
  <div class="container-1">
    <div class="scanner-container">
      <transition name="fade"
        ><alert-modal
          v-if="alertShow"
          :contents="this.contentAlert"
          @close="this.closeAlertModal"
      /></transition>
      <div class="title font-bold">{{ $t("main.scanBarcode") }}</div>
      <div class="subtitle">{{ $t("main.scanBarcodeDesc") }}</div>
      <div class="camera-container">
        <stream-barcode-reader
          @decode="onDecode"
          @loaded="onLoaded"
        ></stream-barcode-reader>
      </div>
      <div class="footer-btn">
        <div class="scan-button">
          <button class="scan-btn btn-right font-regular">
            {{ $t("main.scanBarcodeBtn") }}
          </button>
        </div>
        <div class="code-button">
          <button class="code-btn btn-center font-regular" @click="gotoScanQR()">
            {{ $t("main.scanQrcodeBtn") }}
          </button>
        </div>
        <div class="code-button">
          <button class="code-btn btn-left font-regular" @click="gotoTypeCode()">
            {{ $t("main.typeCode") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import alertModal from "@/components/dialog/alert_modal.vue";
import * as ApiMerchants from "@/utill/api/merchants";
import { token } from "@/utill/jwt";
export default {
  components: {
    "stream-barcode-reader": StreamBarcodeReader,
    "alert-modal": alertModal,
  },
  data() {
    return {
      error: "",
      alertShow: false,
      textDecode: "",
      contentAlert: {},
    };
  },
  methods: {
    gotoScanQR() {
      this.$router.replace("scanqr");
    },
    gotoTypeCode() {
      this.$router.push("typecode");
    },
    onDecode(result) {
      this.textDecode = result;
      // this.showAlertModal(result, "test description");
      this.getCouponDetail(result);
    },
    onLoaded(item) {
      console.log("on loaded", item);
    },
    showAlertModal(title, subtitle) {
      this.contentAlert = {
        title: title,
        desc: subtitle,
        moreDetail: null,
      };
      this.alertShow = true;
    },
    getCouponDetail(code) {
      const body = {
        code: code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        merchant_id: token.getDomain(),
      };
      ApiMerchants.getCouponDetail(body).then((result) => {
        if (result.successful) {
          this.$router.push("coupon_detail");
          this.$store.commit("SET_COUPON_DETAIL", result.data);
        } else {
          // this.showAlertModal("ERROR", "SOME ERROR");
          this.errorHandler(result);
        }
      });
    },
    errorHandler(response) {
      console.log("response", response);
      // INVALID COUPON CODE
      if (response.data.error_code == "CM010") {
        this.showAlertModal(
          this.$t("main.errorInvalidCoupon"),
          this.$t("main.errorInvalidCouponDetail")
        );
      }
    },
    closeAlertModal() {
      this.alertShow = false;
      this.$router.push("home");
    },
  },
};
</script>

<style lang="scss" scoped>
.container-1 {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4) !important;
  padding: 0;
  margin: 0 auto;
}
.scanner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 30px;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: white;
}
::v-deep .overlay-element {
  height: 100% !important;
}

.subtitle {
  text-align: center;
  font-size: 16px;
  color: white;
}

.camera-container {
  width: 90%;
  height: 100%;
  margin: 30px 0 0 0;
  align-self: center;
}

.footer-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  align-self: center;
  background: rgba(0, 0, 0, 0.4);
}

.scan-button {
  justify-content: right;
}

.scan-btn {
  width: auto !important;
  min-width: 90px;
  height: 35px;
  color: black;
  background-color: #e8e8e8;
  border-radius: 3px 0px 0px 3px;
  border: none;
  box-shadow: 0px 2px 3px #d6d6d6;
  transition: 0.15s;
  &:hover {
    background-color: #dcdcdc;
  }
  &:focus {
    outline: 0;
  }
}

.code-button {
  justify-content: left;
}

.code-btn {
  width: auto;
  min-width: 90px;
  height: 35px;
  color: black;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 2px 3px #d6d6d6;
  transition: 0.15s;
  &:hover {
    background-color: #dcdcdc;
  }
  &:focus {
    outline: 0;
  }
}

.btn-left {
  border-radius: 0px 3px 3px 0px;
}

.btn-right {
  border-radius: 3px 0px 0px 3px;
}

.btn-center {
  border-radius: 0px 0px 0px 0px; 
}
</style>
