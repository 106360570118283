/* eslint-disable @typescript-eslint/camelcase */
<template>
  <div
    class="w-100 main-content-detail"
    :style="{ height: windowHeight + 'px' }"
  >
    <div class="main-content-box">
      <transition name="fade"
        ><confirm-modal
          v-if="confirmShow"
          :contents="this.contentConfirm"
          @close="confirmShow = false"
          @confirmUsed="redeemCoupon()"
      /></transition>
      <transition name="fade"
        ><modal-reward
          v-if="modalRewardShow"
          @close="this.closeSumaryModal"
          :contents="modalContents"
      /></transition>
      <transition name="fade"
        ><alert-modal
          v-if="alertShow"
          :contents="this.contentAlert"
          @close="alertShow = false"
      /></transition>
      <div class="privilege-img">
        <img
          :src="
            currentLang == 'en'
              ? contents.promotion.imageEn
              : contents.promotion.imageTh
          "
        />
      </div>
      <div class="title font-bold">
        {{
          currentLang == "en"
            ? contents.promotion.nameEn
            : contents.promotion.nameTh
        }}
      </div>
      <div class="coupon-detail">
        <div class="coupon-code">
          {{ $t("main.couponCode") + ": " + contents.coupon.code }}
        </div>
        <div class="content-inline">
          <div class="icon">
            <img src="../../assets/icons/tag_percent.svg" alt="tag image" />
          </div>
          <div class="txt-status">
            {{ $t("main.couponStatus") + ":" }}
          </div>
          <div
            class="coupon-status-available"
            v-if="contents.coupon.status == 1"
          >
            {{ $t("main.couponAvailable") }}
          </div>
          <div
            class="coupon-status-available"
            v-if="contents.coupon.status == 2"
          >
            {{ $t("main.couponDistributed") }}
          </div>
          <div
            class="coupon-status-unavailable"
            v-if="contents.coupon.status == 3"
          >
            {{ $t("main.couponRedeemed") }}
          </div>
          <div
            class="coupon-status-unavailable"
            v-if="contents.coupon.status == 4"
          >
            {{ $t("main.couponExpired") }}
          </div>
          <div
            class="coupon-status-unavailable"
            v-if="contents.coupon.status == 5"
          >
            {{ $t("main.couponOnHold") }}
          </div>
          <div
            class="coupon-status-unavailable"
            v-if="contents.coupon.status == 6"
          >
            {{ $t("main.couponUnAvailable") }}
          </div>
          <div
            class="coupon-status-unavailable"
            v-if="contents.coupon.status == 7"
          >
            {{ $t("main.couponNotStartedYet") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="reward_date">
        <div class="content-inline">
          <div class="icon">
            <img src="../../assets/icons/calendar.svg" alt="calendar icon" />
          </div>
          <div class="redeem-date">
            {{
              $t("main.redeemDate") +
              " " +
              StringHelper.formatDate(
                contents.promotion.activeStartDate,
                currentLang,
                false
              ) +
              " - " +
              StringHelper.formatDate(
                contents.promotion.activeEndDate,
                currentLang,
                false
              )
            }}
          </div>
        </div>
        <div class="content-inline">
          <div class="icon">
            <img src="../../assets/icons/clock.svg" alt="clock icon" />
          </div>
          <div class="use-date">
            {{
              $t("main.useDate") +
              " " +
              StringHelper.formatDate(
                contents.promotion.redeemStartDate,
                currentLang,
                false
              ) +
              " - " +
              StringHelper.formatDate(
                contents.promotion.redeemEndDate,
                currentLang,
                false
              )
            }}
          </div>
        </div>
      </div>
      <hr />
      <div class="description-container">
        <div class="group-term">
          <div class="term-condition">
            {{ $t("main.description") }}
          </div>
        </div>
        <transition name="fade">
          <div
            class="description"
            v-html="
              currentLang == 'en'
                ? contents.promotion.detailEn
                : contents.promotion.detailTh
            "
          ></div>
        </transition>
      </div>
    </div>
    <div
      v-if="
        !isCouponRedeemed &&
        (this.contents.coupon.status == 1 || this.contents.coupon.status == 2)
      "
      class="btn-container"
    >
      <md-button
        class="primo-button"
        :disabled="sending"
        v-if="
          this.contents.coupon.status == 1 || this.contents.coupon.status == 2
        "
        @click="redeemClick()"
        >{{ $t("main.redeem") }}</md-button
      >
    </div>
  </div>
</template>

<script>
import confirmModal from "@/components/dialog/confirm_modal.vue";
import alertModal from "@/components/dialog/alert_modal.vue";
import modalReward from "@/components/dialog/Modal.vue";
import NavigationBar from "@/components/navigationbar/NavigationBar.vue";
import { StringHelper } from "@/helper/string.ts";
import moment from "moment";
import * as ApiMerchants from "@/utill/api/merchants";
import { token } from "@/utill/jwt";

export default {
  components: {
    // NavigationBar,
    confirmModal,
    modalReward,
    alertModal,
  },
  props: {
    contents: {
      type: Object,
      required: false,
      default() {
        return {
          promotion: {
            id: "",
            statusId: "",
            imageEn: "",
            imageTh: "",
            activeEndDate: "",
            activeStartDate: "",
            detailEn: "",
            detailTh: "",
            nameEn: "",
            nameTh: "",
            redeemEndDate: "",
            redeemStartDate: "",
          },
          coupon: {
            id: "",
            code: "",
            status: "",
          },
        };
      },
    },
  },
  data() {
    return {
      StringHelper,
      windowHeight: window.innerHeight,
      confirmShow: false,
      contentConfirm: {},
      modalRewardShow: false,
      alertShow: false,
      modalContents: {},
      contentAlert: {},
      expand: false,
      sending: false,
      staff:
        this.$store.state.userInfo.name +
        " " +
        this.$store.state.userInfo.lastname,
      isCouponRedeemed: false,
      merchantId: "",
      currentCoupon: {},
      statusList: [
        { id: 1, name: "Available" },
        { id: 2, name: "Distributed" },
        { id: 3, name: "Redeemed" },
        { id: 4, name: "Expired" },
        { id: 5, name: "On hold" },
        { id: 6, name: "Inactive" },
        { id: 7, name: "Not started yet" },
      ],
    };
  },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    // this.getCouponDetail();
    this.currentCoupon = this.$store.state.couponDetail;
    this.updateValueToCoupon();
  },
  methods: {
    checkCouponDate() {
      if (
        moment().diff(moment(this.contents.promotion.redeemStartDate), "days") <
        0
      ) {
        this.contents.coupon.status = 7
      }
    },
    redeemClick() {
      this.contentConfirm = {
        title: this.$t("main.titleUseCoupon"),
        desc: this.$t("main.descUseCoupon"),
      };

      this.confirmShow = true;
    },
    expandDetail() {
      this.expand = !this.expand;
    },
    userConfirmUse() {
      this.confirmShow = false;
      this.isCouponRedeemed = true;
      this.contents.coupon.status = 3;
      this.modalContents = {
        showStatusSuccess: true,
        title: this.$t("main.redeemSuccess"),
        subtitle:
          this.currentLang == "th"
            ? this.contents.promotion.nameTh
            : this.contents.promotion.nameEn,
        staff: this.$t("main.staff") + " " + this.staff,
        transactionDate:
          this.$t("main.tranDate") +
          "<br>" +
          moment().format("DD-MM-YYYY HH:mm:ss"),
        btnText: this.$t("main.ok"),
      };
      this.modalRewardShow = true;
    },
    closeSumaryModal() {
      // this.$router.replace("home");
      this.modalRewardShow = false;
    },
    updateValueToCoupon() {
      this.contents.promotion.nameTh = this.currentCoupon.collcetion_description;
      this.contents.promotion.nameEn = this.currentCoupon.collcetion_description;
      this.contents.coupon.code = this.currentCoupon.code;
      this.contents.coupon.status = this.currentCoupon.status;
      this.contents.promotion.redeemStartDate = this.currentCoupon.redeem_start_date;
      this.contents.promotion.redeemStartDate = this.currentCoupon.redeemEndDate;
      this.contents.promotion.detailEn = this.currentCoupon.collcetion_condition;
      this.contents.promotion.detailTh = this.currentCoupon.collcetion_condition;
      this.contents.promotion.imageEn = this.currentCoupon.collcetion_image_url;
      this.contents.promotion.imageTh = this.currentCoupon.collcetion_image_url;
      this.contents.promotion.activeStartDate = this.currentCoupon.distribute_start_date;
      this.contents.promotion.activeEndDate = this.currentCoupon.distribute_end_date;
      this.contents.promotion.redeemStartDate = this.currentCoupon.redeem_start_date;
      this.contents.promotion.redeemEndDate = this.currentCoupon.redeem_end_date;
      this.checkCouponDate();
    },
    showAlertModal(title, subtitle, moreDetail = null) {
      this.contentAlert = {
        title: title,
        desc: subtitle,
        moreDetail: moreDetail,
      };
      this.alertShow = true;
    },
    redeemCoupon(code) {
      if (this.currentCoupon) {
        const body = {
          code: this.currentCoupon.code,
          // eslint-disable-next-line @typescript-eslint/camelcase
          merchant_id: token.getDomain(),
          // eslint-disable-next-line @typescript-eslint/camelcase
          ref_1: "",
          // eslint-disable-next-line @typescript-eslint/camelcase
          ref_2: "",
        };
        ApiMerchants.redeemCoupon(body).then((result) => {
          if (result.successful) {
            this.userConfirmUse();
          } else {
            this.confirmShow = false;
            setTimeout(() => {
              this.errorHandler(result);
            }, 200);
          }
        });
      }
    },
    errorHandler(response) {
      // ON HOLD
      if (response.data.error_code == "CM018") {
        this.showAlertModal(
          this.$t("main.redeemErrorOnHold"),
          this.$t("main.redeemErrorOnHoldDetail")
        );
      }
      // REDEEMED
      else if (response.data.error_code == "CM015") {
        this.showAlertModal(
          this.$t("main.redeemErrorRedeemed"),
          this.$t("main.redeemErrorRedeemedDetail") +
            "<br>" +
            this.$t("main.redeemErrorRedeemedDetail2") +
            moment(this.currentCoupon).format("DD-MM-YYYY HH:mm:ss"),
          this.$t("main.moreDetail")
        );
      }
      // EXPIRED
      else if (response.data.error_code == "CM016") {
        this.showAlertModal(
          this.$t("main.redeemErrorExpired"),
          this.$t("main.redeemErrorExpiredDetail"),
          this.$t("main.moreDetail")
        );
      }
      // INACTIVE
      else if (response.data.error_code == "CM014") {
        this.showAlertModal(
          this.$t("main.redeemErrorInactive"),
          this.$t("main.redeemErrorInactiveDetail")
        );
      } else if (response.data.error_code == "CM017") {
        this.showAlertModal(
          this.$t("main.redeemErrorRedeemNotStart"),
          this.$t("main.redeemErrorRedeemNotStartDetail"),
          this.$t("main.moreDetail")
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
}
.main-content-detail {
  display: flex;
  flex-direction: column;
}
.main-content-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll !important;
}

.privilege-img {
  width: 100%;
  padding: 0;
  margin: 0;
}
.privilege-img img {
  object-fit: cover;
  width: 100%;
  max-height: 48vw;
}

.title {
  text-align: left;
  letter-spacing: 0px;
  font-size: 22px;
  color: #272727;
  padding: 0px 20px;
  margin-top: 8px;
}

.btn-container {
  width: 100%;
  box-shadow: 0px -3px 10px #c3c3c362;
  position: fixed;
  bottom: 0;
  justify-content: center;
  height: 100px;
  padding: 15px 30px 0px 15px;
  background-color: #ffffff;
}

.description-container {
  padding: 10px 21px 180px 21px;
  flex: 1;
}

.description {
  font-size: 14px;
  line-height: 30px;
  color: #272727;
}

.coupon-detail {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  min-height: 50px !important ;
}

.coupon-code {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  text-align: left;
  color: #808080;
  padding: 0px 0px 0px 5px;
}

.content-inline {
  display: flex;
  flex-direction: row;
}

.txt-status {
  font-size: 12px;
  text-align: left;
  color: #808080;
  padding: 5px 0px 0px 5px;
}

.coupon-status-available {
  color: #06be22;
  font-size: 12px;
  text-align: left;
  padding: 5px 0px 0px 5px;
}

.coupon-status-unavailable {
  color: #ff0000;
  font-size: 12px;
  text-align: left;
  padding: 5px 0px 0px 5px;
}

.icon img {
  width: 20px;
  height: 20px;
}

.reward_date {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  min-height: 50px !important
}

.redeem-date,
.use-date {
  font-size: 14px;
  color: black;
  text-align: left;
  padding: 3px 0px 0px 5px;
}

.term-condition {
  width: 90%;
  font-size: 14px;
  font-weight: bold;
}

.group-term {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.arrow-group {
  width: 10%;
  text-align: right;
}

.icon-up img,
.icon-down img {
  width: 15px;
  height: 15px;
}

@media only screen and (max-width: 320px) {
}
</style>
