<template>
  <div>
    <div class="header-img">
      <img src="../../assets/images/green-water.svg" alt="" />
    </div>
    <div class="merchant">
      <div class="greeting">{{ $t('main.greeting') }}</div>
      <div class="username">คุณ {{ fullName }}</div>
      <div class="merchant-name">ร้านค้า: {{ store }}</div>
      <div class="merchant-role">ตำแหน่ง: {{ role }}</div>
      <hr />
      <div class="img-container">
        <img src="../../assets/images/store.svg" alt="" />
      </div>
      <div class="scan-helper">
        สำหรับการสแกนคูปอง พนักงานโปรดสแกนคูปองจากลูกค้า
      </div>
      <button class="primo-button" @click="gotoScanner()">สแกนคูปอง</button>
      <a class="enter-code" @click="gotoTypeCode()">กรอกรหัส</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Merchant',
  props: ['fullName', 'store', 'role'],
  methods: {
    gotoScanner() {
      this.$router.push('scanqr');
    },
    gotoTypeCode() {
      this.$router.push('typecode');
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant {
  padding: 0 25px;
}

.greeting {
  font-size: 29px;
  text-align: left;
  margin-top: -20px;
}

.username {
  font-family: 'Sukhumvit_Bold';
  font-size: 29px;
  text-align: left;
  margin-top: -10px;
}

.merchant-name, .merchant-role {
  font-size: 16px;
  text-align: left;
}

.header-img {
  width: 100%;
  padding: 0;
  margin: 0;
}

.header-img img {
  width: 100%;
  object-fit: contain;
}

.img-container {
  width: 100%;
  justify-content: center;
}

.img-container img {
  width: 100%;
  object-fit: contain;
}

.scan-helper {
  font-size: 14px;
  text-align: center;
  color: #afafaf;
  margin: 29px 0;
}

.enter-code {
  display: block;
  font-family: 'Sukhumvit_Medium';
  color: black;
  margin-top: 29px;
  text-align: center;
}

@media screen and (max-width: 340px) {
  .greeting {
    font-size: 20px;
    text-align: left;
    margin-top: -50px;
  }

  .username {
    font-size: 20px;
    text-align: left;
  }

  .merchant-name {
    font-size: 15px;
  }

  .scan-helper {
    font-size: 13px;
    margin: 20px 0;
  }

  .enter-code {
    margin-top: 20px;
  }
}
</style>
