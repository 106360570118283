import { render, staticRenderFns } from "./coupon_detail.vue?vue&type=template&id=61fd66ca&scoped=true&"
import script from "./coupon_detail.vue?vue&type=script&lang=js&"
export * from "./coupon_detail.vue?vue&type=script&lang=js&"
import style0 from "./coupon_detail.vue?vue&type=style&index=0&id=61fd66ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fd66ca",
  null
  
)

export default component.exports