import moment from 'moment'

export class StringHelper {
    static formatDate(date: any, locale: any, isFromDate: boolean){
      try {
        if (locale == 'th') {
          const monTh = [
            'ม.ค.',
            'ก.พ.',
            'มี.ค.',
            'เม.ย.',
            'พ.ค.',
            'มิ.ย.',
            'ก.ค.',
            'ส.ค.',
            'ก.ย.',
            'ต.ค.',
            'พ.ย.',
            'ธ.ค.',
          ]
          return (
            moment(date).format('DD') +
            ' ' +
            monTh[parseInt(moment(date).format('MM')) - 1] +
            ' ' + (isFromDate? '':moment(date).add(543, 'years').format('YY')))
        } else {
          const monEn = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]
          return (
            moment(date).format('DD') +
            ' ' +
            monEn[parseInt(moment(date).format('MM')) - 1] +
            ' ' + (isFromDate? '':moment(date).format('YY'))
          )
        }
      } catch (error) {
        return ''
      }
  }

  static checkCouponExpired(date: any)
  {
    try {
      if(date && moment(date).utcOffset(7).format('YYYY-MM-DD 23:59:59') <= moment().format('YYYY-MM-DD HH:mm:ss')){
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  static formatFullDate(date: any, locale: any){
      try {
        if (locale == 'th') {
          const monTh = [
            'มกราคม',
            'กุมภาพันธ์',
            'มีนาคม',
            'เมษายน',
            'พฤษภาคม',
            'มิถุนายน',
            'กรกฎาคม',
            'สิงหาคม',
            'กันยายน',
            'ตุลาคม',
            'พฤษจิกายน',
            'ธันวาคม',
          ]
          return (
            moment(date).format('DD') +
            ' ' +
            monTh[parseInt(moment(date).format('MM')) - 1] +
            ' ' + (parseInt(moment(date).format('YYYY')) + 543))
        } else {
          const monEn = [
            'JANUARY',
            'FEBRUARY',
            'MARCH',
            'APRIL',
            'MAY',
            'JUNE',
            'JULY',
            'AUGUST',
            'SEPTEMBER',
            'OCTOBER',
            'NOVEMBER',
            'DECEMBER',
          ]
          return (
            moment(date).format('DD') +
            ' ' +
            monEn[parseInt(moment(date).format('MM')) - 1] +
            ' ' + (parseInt(moment(date).format('YYYY')))
          )
        }
    } catch (error) {
      return ''
    }
  }
}