<template>
  <div>
    <Header />
    <Sidebar />
    <Merchant
      :fullName="fullName"
      :store="store"
      :role="role"
    />
  </div>
</template>

<script>
import Header from "@/components/navigationbar/Header.vue";
import Sidebar from "@/components/navigationbar/Sidebar.vue";
import Merchant from "@/components/main_page/Merchant.vue";
import * as ApiMerchants from "@/utill/api/merchants";
import { token } from "@/utill/jwt";

export default {
  name: "Home",
  data() {
    return {
    };
  },
  computed: {
    store() {
      if (!this.$store.state.merchantInfo) return '-';
      return this.$store.state.merchantInfo.name;
    },
    fullName() {
      if (!this.$store.state.userInfo) return '-';
      return this.$store.state.userInfo.name + ' ' + this.$store.state.userInfo.lastname;
    },
    role() {
      const roleId = token.getRole(this.$store.state.currentLang);
      if (!roleId) return '-';
      
      return this.$t('main.role' + roleId);
    }
  },
  created() {
    this.$store.dispatch("getUserInfo");
    this.$store.dispatch("getMerchantInfo");
  },
  components: {
    Header,
    Merchant,
    Sidebar,
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>