<template>
  <div class="main-container">
    <transition name="fade"
      ><alert-modal
        v-if="alertShow"
        :contents="this.contentAlert"
        @close="alertShow = false"
    /></transition>
    <md-progress-bar md-mode="indeterminate" v-if="sending" />
    <div class="background-header">
      <img id="header-img" src="../../assets/images/green-water.svg" alt="" />
    </div>
    <div class="content-container">
      <div class="title font-bold">{{ $t("main.typeCodeTitle") }}</div>
      <div class="subtitle">{{ $t("main.typeCodeDesc") }}</div>
      <form novalidate class="md-layout" @submit.prevent="validateCode">
        <div class="input-group">
          <md-field md-clearable :class="getValidationClass('couponCode')">
            <label for="couponCode">{{ $t("main.couponCode") }}</label>
            <md-input
              name="couponCode"
              id="couponCode"
              v-model="form.couponCode"
              :disabled="sending"
            />
            <span class="md-error" v-if="!$v.form.couponCode.required">{{
              $t("main.couponCodeInvalid")
            }}</span>
          </md-field>
        </div>
        <div class="footer">
          <div class="btn">
            <md-button type="submit" class="primo-button" :disabled="sending">{{
              $t("main.confirm")
            }}</md-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import alertModal from "@/components/dialog/alert_modal.vue";
import * as ApiMerchants from "@/utill/api/merchants";
import { token } from "@/utill/jwt";
export default {
  name: "CouponForm",
  mixins: [validationMixin],
  components: {
    "alert-modal": alertModal,
  },
  data() {
    return {
      sending: false,
      alertShow: false,
      contentAlert: {},
      form: {
        couponCode: null,
      },
    };
  },
  validations: {
    form: {
      couponCode: {
        required,
      },
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },
    validateCode() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.sendCode();
      }
    },
    sendCode() {
      this.getCouponDetail(this.form.couponCode);
    },
    getCouponDetail(code) {
      this.sending = true;
      const body = {
        code: code,
        // eslint-disable-next-line @typescript-eslint/camelcase
        merchant_id: token.getDomain(),
      };
      ApiMerchants.getCouponDetail(body).then((result) => {
        if (result.successful) {
          this.$router.push("coupon_detail");
          this.$store.commit("SET_COUPON_DETAIL", result.data);
          this.sending = false;
        } else {
          this.errorHandler(result);
          this.sending = false;
        }
      });
    },
    showAlertModal(title, subtitle) {
      this.contentAlert = {
        title: title,
        desc: subtitle,
        moreDetail: null,
      };
      this.alertShow = true;
    },
    errorHandler(response) {
      // INVALID COUPON CODE
      if (response.data.error_code == "CM010") {
        this.showAlertModal(
          this.$t("main.errorInvalidCoupon"),
          this.$t("main.errorInvalidCouponDetail")
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  margin: 60% 0 0 0;
  padding: 25px;
  z-index: 1;
}

.background-header {
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
}

.background-header img {
  width: 100%;
  height: auto;
}

.title {
  font-size: 29px;
  font-weight: bold;
  text-align: left;
}

.subtitle {
  font-size: 16px;
  text-align: left;
}

.footer {
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 0px;
  margin: 10px 0px 0px 0px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 100%;
  margin: 0;
  padding: 0px 15px 0px 0px;
}

.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media screen and (max-width: 340px) {
  .title {
    font-size: 25px;
    text-align: left;
  }

  .content-container {
    margin: 30% 0 0 0;
  }
}
</style>
