<template>
  <div class="w-100">
    <NavBar :title="$t('main.typeCode')" />
    <TypeCode />
  </div>
</template>

<script>
import TypeCode from '@/components/main_page/TypeCode.vue';
import NavBar from '@/components/navigationbar/NavigationBar.vue';
export default {
  name: 'typecode',
  data() {
    return {};
  },
  components: {
    TypeCode,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
  height: 100%;
}
</style>
